@font-face {
    font-family: 'Proxima';
    src: url('./assets/fonts//ProximaNova-Regular.eot');
    src: url('./assets/fonts//ProximaNova-Regular.eot?#iefix') format('embedded-opentype'), url('./assets/fonts//ProximaNova-Regular.woff2') format('woff2'), url('./assets/fonts//ProximaNova-Regular.woff') format('woff'), url('./assets/fonts//ProximaNova-Regular.ttf') format('truetype'), url('./assets/fonts//ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima';
    src: url('./assets/fonts//ProximaNova-Bold.eot');
    src: url('./assets/fonts//ProximaNova-Bold.eot?#iefix') format('embedded-opentype'), url('./assets/fonts//ProximaNova-Bold.woff2') format('woff2'), url('./assets/fonts//ProximaNova-Bold.woff') format('woff'), url('./assets/fonts//ProximaNova-Bold.ttf') format('truetype'), url('./assets/fonts//ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima';
    src: url('./assets/fonts//ProximaNova-Extrabld.eot');
    src: url('./assets/fonts//ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'), url('./assets/fonts//ProximaNova-Extrabld.woff2') format('woff2'), url('./assets/fonts//ProximaNova-Extrabld.woff') format('woff'), url('./assets/fonts//ProximaNova-Extrabld.ttf') format('truetype'), url('./assets/fonts//ProximaNova-Extrabld.svg#ProximaNova-Extrabld') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima';
    src: url('./assets/fonts//ProximaNova-Semibold.eot');
    src: url('./assets/fonts//ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'), url('./assets/fonts//ProximaNova-Semibold.woff2') format('woff2'), url('./assets/fonts//ProximaNova-Semibold.woff') format('woff'), url('./assets/fonts//ProximaNova-Semibold.ttf') format('truetype'), url('./assets/fonts//ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima';
    src: url('./assets/fonts//ProximaNova-Light.eot');
    src: url('./assets/fonts//ProximaNova-Light.eot?#iefix') format('embedded-opentype'), url('./assets/fonts//ProximaNova-Light.woff2') format('woff2'), url('./assets/fonts//ProximaNova-Light.woff') format('woff'), url('./assets/fonts//ProximaNova-Light.ttf') format('truetype'), url('./assets/fonts//ProximaNova-Light.svg#ProximaNova-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima';
    src: url('./assets/fonts//ProximaNovaT-Thin.eot');
    src: url('./assets/fonts//ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'), url('./assets/fonts//ProximaNovaT-Thin.woff2') format('woff2'), url('./assets/fonts//ProximaNovaT-Thin.woff') format('woff'), url('./assets/fonts//ProximaNovaT-Thin.ttf') format('truetype'), url('./assets/fonts//ProximaNovaT-Thin.svg#ProximaNovaT-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


/* ======================Fonts end======================== */

.text_color_pink {
    color: #FE6290 !important;
}

.text_color_light_blue {
    color: #89CFF0 !important;
}

.text_color_lightgrey {
    color: #888 !important;
}

.text_color_darkgrey {
    color: #474747 !important;
}

.fs18 {
    font-size: 18px !important;
}

.fs20 {
    font-size: 20px;
}

.fs24 {
    font-size: 24px !important;
}

.btn {
    border-radius: 6px;
    font-size: 18px;
    padding: 6px 50px;
}

.btn-primary {
    color: #fff;
    background-color: #009EFF;
    border-color: #0096f3;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
}

.btn-primary-light {
    color: #0096f3;
    background-color: #CBEBFF;
    border-color: #CBEBFF;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
}

.btn-primary-light:hover {
    color: #ffffff;
    background-color: #0096f3;
    border-color: #0096f3;
}

.btn-primary-outline {
    color: #009EFF;
    background-color: transparent;
    border-color: #0096f3;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
}

.btn-primary-outline:hover {
    color: #fff;
    background-color: #0096f3;
    border-color: #0096f3;
}


/* .btn-primary:hover {
    color: #fff;
    background-color: #4E5AFF;
    border-color: #3a48ff;
} */

.btn-info {
    color: #fff;
    background-color: #89CFF0;
    border-color: #7eccf0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
}

.btn-info:hover {
    color: #fff;
}

.fw_semibold {
    font-weight: 600;
}

.fw_bold {
    font-weight: bold;
}

.fw_extrabold {
    font-weight: 800;
}

.fw_normal {
    font-weight: normal;
}

.fw_light {
    font-weight: 300;
}

a {
    color: #009EFF;
    text-decoration: none;
}

a:hover {
    color: #000;
    text-decoration: underline;
}

body {
    background-color: #F6F6F6;
}

body,
html {
    font-family: 'Proxima';
    color: #888888;
    font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
.display-6 {
    font-weight: bold;
    color: #232322;
}
.fit-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

form .form-label {
    color: #232322;
    font-weight: 600;
}

.banner::before,
.banner::after {
    width: 100%;
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
}

.banner::before {
    background: url(./assets/images/banner_top_left.svg) no-repeat left top;
    height: 131px;
}

.banner::after {
    background: url(./assets/images/banner_shape_right.svg) no-repeat right top;
    height: 620px;
}

.logo::after {
    width: 462px;
    content: "";
    position: absolute;
    top: 10px;
    background: url(./assets/images/plane_with_border.svg) no-repeat left top;
    height: 127px;
}

.logo {
    padding-top: 3rem;
}

.banner_text h1 {
    font-weight: 700;
}

.banner_text h4 {
    color: #474747;
}

.banner_text {
    max-width: 80%;
    width: 100%;
}

.banner_btns::before {
    width: 85px;
    content: "";
    position: absolute;
    top: -30px;
    left: -80px;
    background: url(./assets/images/banner_text_shape.svg) no-repeat left top;
    height: 165px;
}


/* ====================About us section css start======================== */

.about_brief::before,
.about_brief::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 192px;
}

.about_brief::before {
    background: url(./assets/images/about_shapes_right.svg) no-repeat top right;
}

.about_brief::after {
    background: url(./assets/images/about_shapes_left.svg) no-repeat bottom left;
    bottom: 0;
}

.about_brief_inner {
    max-width: 880px;
    width: 100%;
    margin: 0 auto;
}

.about_brief_inner h4 {
    padding: 0 6rem;
}


/* h3.heading_with_lines::before,
h3.heading_with_lines::after {
    background-color: #F7C3C3;
    content: "";
    height: 3px;
    position: absolute;
    left: 100%;
    margin-left: 20px;
}

h3.heading_with_lines::after {
    width: 66px;
    top: 22px;
}

h3.heading_with_lines::before {
    width: 108px;
    top: 34px;
} */

.button_3d.btn_black {
    background-color: #232322;
}

.button_3d.btn_blue {
    background-color: #009EFF;
}

.button_3d:hover {
    color: #fff;
}

.button_3d {
    color: #fff;
    border-radius: 50px;
    width: 217px;
    height: 68px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.16);
    padding: 12px 6px;
    line-height: 1.2;
}

.button_3d.btn_black .icon_container {
    box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, 1), inset -3px -3px 6px 0 rgba(255, 255, 255, 0.10);
}

.button_3d.btn_blue .icon_container {
    box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, .10), inset -3px -3px 6px 0 rgba(255, 255, 255, 0.24)
}

.icon_container {
    display: inline-block;
    width: 54px;
    height: 54px;
    border-radius: 50%;
}

.store_name {
    font-size: 22px;
    font-weight: 600;
}

.download_btns button {
    font-size: 12px;
}


/* ====================About us section css End======================== */


/* ====================Features css start======================== */

.features_card_container img {
    max-width: 100px;
    width: 100%;
}

.features_card_container {
    padding: 0 1rem;
}

.features_card_container .card {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.10);
    border: none;
    border-radius: 40px;
}

.features_content_container {
    max-width: 450px;
    width: 100%;
    margin-left: 5vw;
}

.features_content_container h3 {
    max-width: 320px;
}

.features_content_container h3 i img {
    margin-top: -10px;
}


/* ========Common sections css start=========== */

section.common_padding {
    padding: 11em 6vw;
    overflow: hidden;
    margin-top: 8vw;
}

.common_poly_bg::before,
.common_padding::after {
    content: "";
    position: absolute;
    background-size: auto;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.common_padding::after {
    background: url(./assets/images/feature_shape_bg2.svg) no-repeat right center;
    right: 0;
    top: 0;
}

.common_poly_bg::before {
    background: url(./assets/images/feature_bg_shape.svg) no-repeat left center;
    left: 0;
    top: 0;
}


/* ========Common sections css end=========== */


/* =============What we do css start================ */

.what_we_do.common_padding::after {
    background: url(./assets/images/wedo_shape2.svg) no-repeat left center;
    left: 0;
    top: 0;
}

.what_we_do .common_poly_bg::before {
    background: url(./assets/images/wedo_shape.svg) no-repeat right top;
    right: 0;
    top: 0;
}

.video_play_icon {
    position: absolute;
    bottom: -50px;
    right: 50%;
}

.modal-dialog iframe {
    margin: 0 auto;
    display: block;
}

.listing li {
    position: relative;
    padding-left: 2.3rem;
    margin-bottom: 1.2rem;
}

.listing li::before {
    position: absolute;
    content: "";
    background: url(./assets/images/polygon_bullet.svg) no-repeat center left;
    width: 100%;
    height: 100%;
    left: 0;
}

.what_we_do .common_poly_bg {
    max-width: 540px;
    width: 100%;
}

.what_we_do.common_padding {
    padding: 32vh 6vw;
    overflow: hidden;
}


/* =============What we do css end================ */


/* ============= Contact Us css start================ */

.contactUs .common_poly_bg::before {
    background: url(./assets/images/getintouch_shape.svg) no-repeat left top;
}

.getinTouch {
    max-width: 540px;
    width: 100%;
}

.form-floating>label {
    color: #CECECE;
}


/* ============= Contact Us css end================ */


/* ================Footer css start ================ */

footer::before,
footer::after {
    position: absolute;
    content: "";
    bottom: 0;
    height: 100%;
    width: 110px;
}

footer::before {
    background: url(./assets/images/footer_left_shapes.svg) no-repeat bottom left;
}

footer::after {
    background: url(./assets/images/footer_right_corner_shape.svg) no-repeat bottom right;
    right: 0;
}

footer {
    background-color: #EFF6F9;
}

.social_media li:not(:last-child) {
    margin-right: 1rem;
}

footer .footer_logo img {
    max-width: 160px;
}


/* ================Footer css end ================ */


/* Login page css start */

.card {
    border-radius: 1rem;
    border: none;
    box-shadow: 0 9px 15px rgba(0, 0, 0, .10);
}

.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #C5C5C5;
    opacity: 1;
    /* Firefox */
}

.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #C5C5C5;
}

.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #C5C5C5;
}

.form-control-lg,
.form-select-lg {
    font-size: 1rem;
}


/* .copyright_footer {
    height: 80px;
}

.page_fullheight {
    height: calc(100vh - 80px);
} */

.intro p {
    font-size: 20px;
}

.intro {
    max-width: 420px;
    width: 100%;
}

.card_top_shape {
    position: relative;
}

.card_top_shape::before {
    content: "";
    position: absolute;
    background: url(./assets/images/app/card_top_shape.svg) no-repeat;
    top: -40px;
    height: 40px;
    margin: 0 auto;
    left: 0;
    width: 150px;
    right: 0;
}

.card_circle_btn button.btn {
    background-color: #000;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    background-color: #FE6290;
    left: 0;
    right: 0;
    top: -25px;
}

.modal-header button.btn-close {
    top: 20px;
    position: absolute;
    right: 20px;
}

.modal-content {
    border-radius: 20px;
}

.or_divide::before,
.or_divide::after {
    content: "";
    position: absolute;
    background-color: #89CFF0;
    height: 1px;
    width: 20%;
    top: 12px;
}

.or_divide::before {
    right: 55%;
}

.or_divide::after {
    left: 55%;
}

.btn_lighter_blue.btn-info {
    background-color: #EFF6F9;
    border: none;
    color: #888888;
    box-shadow: none;
    padding: 1rem 0;
    font-size: 22px;
}

.btn-check:active+.btn-info.btn_lighter_blue,
.btn-check:checked+.btn-info.btn_lighter_blue,
.btn_lighter_blue.btn-info.active,
.btn_lighter_blue.btn-info:active,
.show>.btn-info.btn_lighter_blue.dropdown-toggle {
    color: #fff;
    background-color: #89CFF0;
    border-color: #89CFF0;
    box-shadow: 0 8px 20px rgba(0, 158, 255, 0.20);
}


/* Register form css start */

.form-check-input {
    width: 1.1em;
    height: 1.1em;
    border: 1px solid #FE6290;
    border-radius: 0px;
}

.form-check-input[type=checkbox] {
    border-radius: 0;
}

.form-check-input:checked {
    background-color: #FE6290;
    border-color: #FE6290;
}

.profile_container {
    width: 160px;
    height: 160px;
    background-color: #EFEFEF;
    border: 3px solid #fff;
    border-radius: 100%;
    overflow: hidden;
}

.profile_container img {
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
}

.profile_container img.userImg {
    height: 100%;
    width: 100%;
}

.profile_img input[type=file] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.profile_camera {
    width: 40px;
    height: 40px;
    bottom: 0;
    right: 20px;
}

.fixed_button {
    bottom: 60px;
    z-index: 31;
    left: auto;
    right: auto;
}


/* verical scroll css */

#vcScroll {
    height: calc(100vh - 200px);
    overflow: auto;
    position: relative;
    padding: 0px 12px 30px;
    margin: 10px;
}

.ssb_down {
    background-color: #89CFF0;
    bottom: 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    border-radius: 10px;
}

.ssb_sb {
    background-color: #89CFF0;
    cursor: pointer;
    position: absolute;
    right: 0;
    border-radius: 10px;
}

.ssb_sb_down {
    background-color: #009EFF;
}

.ssb_sb_over {
    background-color: #009EFF;
}

.ssb_st {
    background-color: #EFF6F9;
    cursor: pointer;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 10px;
}

.ssb_up {
    background-color: #89CFF0;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 10px;
}


/* verical scroll end */


/* OTP popup css start */

.digit-group input {
    width: 50px;
    height: 50px;
    background-color: #fff;
    line-height: 50px;
    text-align: center;
    border: 1px solid #CCCCCC;
    font-size: 30px;
    font-weight: bold;
    color: #009EFF;
    border-radius: 8px;
    margin: 0 8px;
}

.change_bg.filled-background {
    background-color: #DFF4FF !important;
}

.change_bg:focus-visible {
    outline: none;
}


/*==================Create account page css start==================*/

.vcScroll>.vcScroll {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0 !important;
}

#msform #vcScroll {
    height: calc(100vh - 38vh);
    padding: 0px !important;
}

.multi_step_form #msform {
    position: relative;
    padding-top: 20px;
    /* min-height: 400px;
    width        : 100%;
    margin       : 0 auto;
    background   : #ffffff; */
    z-index: 1;
    /* min-height: 820px; */
}

fieldset.fieldset_padding {
    padding-right: 1.5rem !important;
}

.multi_step_form #msform fieldset {
    border: 0;
    padding: 0 0.8rem 20px;
    /* padding: 20px 1.5rem 0; */
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
}

.multi_step_form #msform fieldset:not(:first-of-type) {
    display: none;
}

.multi_step_form #msform #progressbar {
    margin-bottom: 20px;
    overflow: hidden;
}

.multi_step_form #msform #progressbar li {
    list-style-type: none;
    color: #99a2a8;
    font-size: 16px;
    width: calc(100%/4);
    float: left;
    position: relative;
}

.multi_step_form #msform #progressbar li:before {
    content: "";
    width: 10px;
    height: 10px;
    line-height: 50px;
    display: block;
    background: #eaf0f4;
    border-radius: 50%;
    margin: 0 auto 5px auto;
}

.multi_step_form #msform #progressbar li:after {
    content: "";
    width: 100%;
    height: 5px;
    background: #eaf0f4;
    position: absolute;
    left: -50%;
    top: 3px;
    z-index: -1;
}

.multi_step_form #msform #progressbar li:last-child:after {
    width: 150%;
}

.multi_step_form #msform #progressbar li.active {
    color: #FE6290;
}

.multi_step_form #msform #progressbar li.active:before,
.multi_step_form #msform #progressbar li.active:after {
    background: #FE6290;
    color: white;
}


/* .steppers .btn.next,   .steppers .btn.previous_button{
      position: fixed;
      bottom: 50px;
      z-index: 2;
  } */

.steppers .btn {
    padding: 6px 30px;
}


/* image checkbox css start */

.imgcheckbox_container label {
    width: 100px;
    height: 100px;
}

.imgcheckbox_container input {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 100%;
    visibility: visible !important;
    opacity: 0;
}

.imgcheckbox_container label img {
    object-fit: cover;
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.imgcheckbox_container label.img_overlay:before {
    position: absolute;
    content: "";
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.70);
    height: 100px;
    width: 100px;
    z-index: 1;
    display: block;
    left: 0;
    right: 0;
    border-radius: 50%;
}

.imgcheckbox_container {
    margin-right: 0.9rem;
    margin-bottom: 1.5rem;
}

.interest_check {
    position: absolute;
    top: -10px;
    z-index: 1;
    right: 0;
    display: none;
}

.imgcheckbox_container input:checked+label::before {
    display: none;
}

.imgcheckbox_container input:checked+label+.interest_check {
    display: block;
}


/* Personal Details css*/

.form-check label {
    margin-top: 2px;
}

.height {
    display: none;
}


/* Connect with social css */

.connectTabs .nav-pills .nav-link {
    background: #F1F1F1;
    color: #888888;
    padding: 0.8rem;
}

.connectTabs .nav-pills .nav-link.active,
.connectTabs .nav-pills .show>.nav-link {
    color: #fff;
}

.connectTabs .nav-pills .facebookBg.nav-link.active,
.connectTabs .nav-pills .show>.facebookBg.nav-link {
    background-color: #3A5794;
}

.connectTabs .nav-pills .youtubeBg.nav-link.active,
.connectTabs .nav-pills .show>.youtubeBg.nav-link {
    background-color: #FE0000;
}

.connectTabs .nav-pills .instaBg.nav-link.active,
.connectTabs .nav-pills .show>.instaBg.nav-link {
    background-color: #E73085;
}

.connectTabs .nav-pills .tiktokBg.nav-link.active,
.connectTabs .nav-pills .show>.tiktokBg.nav-link {
    background-color: #000;
}

.connectTabs li.nav-item:not(:last-child) {
    margin-right: 0.65rem;
}

.connectTabs .nav-pills .nav-link svg {
    margin-right: 5px;
    fill: #888;
}

.connectTabs .nav-pills .nav-link.active svg,
.connectTabs .nav-pills .show>.nav-link svg {
    fill: #fff;
}

@media (min-width:1800px) {
    .banner::after {
        height: 100%;
        background-size: auto 750px;
    }
}


/* ======================Css By Sandeep================= */


/* ====================Common Css start here =================*/

.fs10 {
    font-size: 10px !important;
}

.fs12 {
    font-size: 12px !important;
}

.fs14 {
    font-size: 14px !important;
}

.fs16 {
    font-size: 16px !important;
}

.fs22 {
    font-size: 22px !important;
}

.fs28 {
    font-size: 28px !important;
}

.fs34 {
    font-size: 34px !important;
}

.fs30 {
    font-size: 30px !important;
}

.fs40 {
    font-size: 40px !important;
}

.text_color_blue {
    color: #009EFF !important;
}

.flex-1 {
    flex: 1 !important;
}

.btn-light-pink {
    color: #FE6290;
    background-color: #FFEEF3;
    border-color: #FFEEF3;
    /* box-shadow      : 0 5px 10px rgba(0, 0, 0, 0.16); */
}

.btn-light-pink:hover {
    color: #fff;
    background-color: #FE6290;
    text-decoration: none;
}

.btn-pink {
    color: #ffffff;
    background-color: #FE6290;
    border-color: #FE6290;
    /* box-shadow      : 0 5px 10px rgba(0, 0, 0, 0.16); */
}

.btn-pink:hover {
    color: #fff;
    background-color: #fc4078;
    text-decoration: none;
}

.cmn-card {
    border-radius: 1rem;
    border: none;
    box-shadow: 0 9px 15px rgb(0 0 0 / 10%);
    padding: 25px;
    background-color: #fff;
}

.object-cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.brdcrm-sec {
    margin: 35px 0 30px 0;
}

section.brdcrm-sec h2::before {
    FONT-VARIANT: JIS04;
    content: '';
    height: 26px;
    width: 2px;
    background-color: #DBDBDB;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -1px;
}

section.brdcrm-sec h2 {
    margin-left: 12px;
    padding-left: 12px;
    position: relative;
}

.no-shadow {
    box-shadow: none !important;
}

.nav-tabs.cmn-tabs {
    border-bottom: 2px solid #DBDBDB;
}

.nav-tabs.cmn-tabs .nav-link {
    color: #CECECE;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin-bottom: -2px;
    padding: 10px 37px;
}

.nav-tabs.cmn-tabs .nav-item.show .nav-link,
.nav-tabs.cmn-tabs .nav-link.active {
    background-color: #FE6290;
    color: #fff;
}

.bookmarkbtn {
    height: 47px;
    width: 47px;
    padding: 0;
    background-color: #F1F1F1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bookmarkbtn.bookmarked {
    background-color: #FE6290;
}

.bookmarkbtn.bookmarked svg path {
    fill: #fff;
}

.border-bottom-heading {
    border-bottom: 1px solid #DBDBDB;
    margin-bottom: 25px;
    padding-bottom: 18px;
}

.booking-status {
    position: relative;
    margin-right: 30px;
}


/* ==============Header css start here================ */

.header {
    background-color: #009EFF;
}

.header .navbar-nav .nav-link {
    text-decoration: none;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
}

header .navbar-nav .nav-link.cstm::after {
    position: absolute;
    bottom: 0;
    content: '';
    width: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: 0.3s all;
    height: 2px;
    background-color: #fff;
}

.header .navbar-nav .nav-link.cstm:hover::after,
.header .navbar-nav .nav-link.cstm.active::after {
    width: 50%;
}

.header .notification-outer {
    background-color: #33B1FF;
    height: 47px;
    width: 47px;
    border-radius: 50%;
    border: 2px solid #0AA1FF;
}

.header .notification-outer .status {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: #FFEC94;
    position: absolute;
    top: 0;
    right: 0;
}

.header .usertogl .img-wrap {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
}

.header .usertogl .content-sec {
    flex: 1
}

.header .usertogl::after {
    border-top: .4em solid #fff;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
    margin-left: 10px;
}

.header .dropdown-menu {
    right: 24px;
    left: auto;
    border: none;
    box-shadow: 0 3px 6px rgb(155 155 155 / 16%);
    padding: 5px 11px;
    border-radius: 0;
    ;
}

.header .dropdown-menu li .dropdown-item {
    position: relative !important;
    padding-left: 40px;
    border-bottom: 1px solid #DBDBDB;
    padding-top: 8px;
    padding-bottom: 8px;
    text-decoration: none !important;
}

.header .dropdown-menu li:last-child .dropdown-item {
    border-bottom: none;
}

.header .dropdown-menu li .dropdown-item .icn-outer {
    position: absolute;
    left: 0;
    height: 30px;
    width: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.header .notification {
    min-width: 380px;
    padding: 8px 15px;
}

.header .notification .item-sec .img-wrap {
    height: 46px;
    width: 46px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
}

.header .notification .item-sec {
    padding: 8px 5px;
}

.header .notification .item-sec .img-wrap img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.header .notification .item-sec .content-sec {
    flex: 1;
}

.header .notification h3 {
    padding: 9px 5px;
    border-bottom: 1px solid #DBDBDB;
}

.header .notification h3 span {
    font-size: 14px;
    background-color: #FE6290;
    color: #fff;
    border-radius: 2px;
    padding: 5px 5px;
    font-weight: 400;
    display: inline-block;
    margin: 0px 0 0 5px;
    position: relative;
    top: -1px;
}

.header .notification h4 {
    margin-bottom: 10px;
}

.header .notification p {
    margin-bottom: 10px;
}


/* ==================campaign-sec css start here================== */

.post-sec {
    background: url('./assets/images/post_card_bg.svg') #fff;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 100px;
}

.search_outer .form-control {
    background-image: url(./assets/images/search_pink.svg);
    background-repeat: no-repeat;
    background-position: top 9px left 14px;
    padding-left: 47px;
    background-size: 21px;
    line-height: 1;
}

.cmn-post {
    padding-top: 45px;
}

.cmn-post .user-sec .img-wrap {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    overflow: hidden;
}

.cmn-post .user-sec .content-sec p img {
    margin-top: -3px;
}

.cmn-post .post-media {
    width: 100%;
    /* min-height: 330px; */
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    aspect-ratio: 2/1;
}

.cmn-post .post-media .edit-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: #fff;
    border-radius: 5px;
    width: 70px;
    padding: 0;
    height: 28px;
    font-size: 16px;
    color: #232322;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cmn-post .post-content p img.locationimg {
    margin-top: -3px;
}


/* ========pagination css strat here======== */

.pagination-wrap {
    border-top: 1px solid #DBDBDB;
    padding-top: 24px;
    margin-top: 35px;
}

.pagination .page-link {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #CECECE;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    border-radius: 3px !important;
    margin: 0 7px !important;
    box-shadow: none;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
    background-color: #F1F1F1;
    color: #888888;
    font-size: 24px;
}

.pagination .page-item.active .page-link {
    background-color: #F7C3C3;
    color: #fff;
}


/* =====================profile box-css start here================ */

.border-wrap {
    height: 130px;
    width: 130px;
    border-radius: 50%;
    padding: 7px;
    background: linear-gradient(135deg, #89CFF0, #E4C5CB);
    position: relative;
}

.border-wrap .verifyimg {
    position: absolute;
    bottom: 7px;
    right: 7px;
    width: 32px;
}

.border-wrap .img-wrap {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
}

.border-wrap .img-wrap img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.profile-sec {
    background-image: url('./assets/images/profile_top_bg.svg');
    background-position: top 10px right 0;
    background-repeat: no-repeat;
}

.follower-sec {
    background-color: #F7C3C3;
    border-radius: 6px;
    padding: 15px 20px;
    justify-content: space-between;
    margin-top: 35px;
}

.follower-sec .divider {
    width: 1px;
    background-color: #fff;
}

.gallary .img-wrapper {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 22px;
}

.gallary .img-wrapper img {
    width: 100%;
}


/* ===========My profile page css start here============= */


/* ===============insights-sec css start here============ */

.insights-sec h2 {
    margin: 0;
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 18px;
    margin-bottom: 0px;
}

.insights-sec .inner-sec {
    border: 1px solid #DBDBDB;
    border-radius: 6px;
    padding: 11px 15px;
    margin-top: 30px;
}

.insights-sec .content-sec h4 {
    margin-bottom: 3px;
}

.insights-sec .content-sec h3 {
    margin-bottom: 17px;
    display: flex;
    align-items: center;
}

.insights-sec .content-sec h3 img {
    margin-right: 9px;
}

.insights-sec .progress-sec {
    width: 80px;
}

.gallary-content .img-wrapper {
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
}

.gallary-content .img-wrapper img {
    width: 100%;
}

.gallary-content .btn.see-all {
    background-color: #F1F1F1;
    width: 100%;
    border-radius: 15px;
    padding: 0;
    color: #FE6290;
}


/* =============reviews css start here============ */

.review-itm {
    box-shadow: 0 3px 8px rgb(0 0 0 / 6%);
    border-radius: 8px;
    padding: 15px;
    margin-top: 25px;
    background-color: #FEFEFE;
}

.review-itm .img-wrap {
    height: 70px;
    width: 70px;
    border-radius: 15px;
    overflow: hidden;
}

.review-itm .img-wrap img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.review-itm .star-sec img {
    margin-left: 6px;
}

.review-itm .btn.btn-primary {
    position: absolute;
    right: 0;
    top: 0;
}

.review-itm.client {
    border: 1px solid #CECECE;
}

.review-itm.client .img-wrap {
    height: 116px;
    width: 116px;
    border-radius: 50%;
}

.review-itm.client .star-sec img {
    margin-left: 6px;
    width: 16px;
}


/* ============travelling-sec css start here============== */

.travelling-sec .content-sec {
    max-width: 570px;
}


/* ===================Collaborations css start here================== */

.collabs-itm {
    box-shadow: 0 3px 8px rgb(0 0 0 / 6%);
    border-radius: 8px;
    padding: 15px;
    margin-top: 25px;
    background-color: #FEFEFE;
}

.collabs-itm .img-wrap {
    height: 170px;
    width: 185px;
    border-radius: 20px;
    overflow: hidden;
}

.collabs-itm .img-wrap img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.collabs-itm .content-sec p {
    line-height: 21px;
}

.client-history .status,
.collabs-itm .status {
    display: inline-block;
    padding: 8px 18px;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
}

.client-history .status.ongoing,
.collabs-itm .status.ongoing {
    background-color: #FFB15E;
}

.client-history .status.completed,
.collabs-itm .status.completed {
    background-color: #39CB33;
}

.client-history .status.not-action,
.collabs-itm .status.not-action {
    background-color: #FF2727;
}

.client-history .status.signed,
.collabs-itm .status.signed {
    background-color: #89CFF0;
}

.client-history .status.unsigned,
.collabs-itm .status.unsigned {
    background-color: #E8E8E8;
    color: #888888;
}


/* ===============job detail css start here============== */

.coverimg-wrap {
    width: 100%;
    height: 250px;
    border-radius: 1rem;
    overflow: hidden;
}

.coverimg-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-job .userimg-wrap {
    height: 95px;
    width: 95px;
    border-radius: 20px;
    border: 3px solid #FFFFFF;
    overflow: hidden;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
}

.profile-job .top-sec {
    padding-bottom: 47px;
}

.profile-job .social-links {
    padding-top: 16px;
}

.profile-job .social-links a {
    margin: 0 15px 30px 15px;
}

.profile-job .status {
    width: fit-content;
    border-radius: 5px;
    padding: 10px 30px;
}

.profile-job .status.ongoing {
    background-color: #FFB15E;
}

.job-detail .head-sec {
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 9px;
}

.job-detail .post-media .img-wrap {
    position: relative;
}

.job-detail .post-media .img-wrap .play-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: fit-content;
}

.job-detail .post-media .img-wrap video{
    width: 100%;
}

.job-detail .post-media .img-wrap .play-btn img {
    width: 70px;
}

.job-detail .post-media a {
    height: 68px;
    width: 68px;
    border-radius: 50%;
    background-color: #EEEEEE;
    margin-top: -34px;
    position: relative;
    margin-right: 25px;
}

.job-detail .guidelines li {
    position: relative;
    padding-left: 18px;
}

.job-detail .guidelines li::after {
    position: absolute;
    top: 0;
    bottom: 0;
    content: '';
    background-color: #FE6290;
    height: 2px;
    width: 8px;
    border-radius: 1px;
    left: 0;
    margin: auto;
}

.job-detial-sec .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #dcdcdc;
    border: 0;
    transition: opacity .6s ease;
    opacity: 1;
}

.job-detial-sec .carousel-indicators [data-bs-target].active {
    width: 22px;
    border-radius: 4px;
    background-color: #fe6290;
}

.job-detial-sec .carousel {
    margin-bottom: 50px;
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 10px;
}

.job-detial-sec .carousel-indicators {
    bottom: -45px;
}

.job-detial-sec .carousel-control-next,
.job-detial-sec .carousel-control-prev {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    bottom: auto;
    right: 30px;
    background-color: #e9e9e9;
    top: -25px;
    z-index: 999;
    opacity: 1;
    box-shadow: 0 3px 17px rgb(0 0 0 / 11%);
}

.job-detial-sec .carousel-control-prev {
    left: auto;
    right: 85px;
}

.voucher {
    border: 1.5px dashed #89CFF0;
    border-radius: 20px;
    padding: 16px 15px;
    background-image: url(./assets/images/reward-sec-bg.png);
    background-repeat: no-repeat;
    background-position: center left;
}

.voucher .price-sec {
    background-image: url(./assets/images/price-value-bg.png);
    min-height: 121px;
    min-width: 123px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.voucher .price-sec p {
    margin-bottom: 8px;
    color: #FFEC94;
}

.timeline-box {
    background-color: #EFF6F9;
    border-radius: 6px;
    padding: 27px 15px;
    margin: 0 15px;
}

.timeline-box h4 {
    margin-bottom: 20px;
}

.timeline-box h5 {
    background-color: #fff;
    padding: 14px 18px;
    border-radius: 6px;
    box-shadow: 0 3px 20px rgba(137 207 240 / 15%);
}


/* =========Add review modal css include here============== */

.review-modal .img-wrap {
    height: 134px;
    width: 134px;
    border-radius: 50%;
    overflow: hidden;
}

.review-modal .star-sec img {
    width: 35px;
}

.later {
    width: fit-content;
    text-decoration: underline;
    margin-top: 20px;
}


/* ===============job detail review box css============ */

.review-job h2 {
    border-bottom: 1px solid #DBDBDB;
    margin-top: -6px;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.review-job ul li img {
    margin-left: 5px;
    width: 16px;
}


/* ============Settings menu css include here=============== */

.settings-menu .border-wrap {
    height: 61px;
    width: 61px;
    padding: 4px
}

.settings-menu .top-sec {
    border-bottom: 1px solid #DBDBDB;
}

.settings-menu .menu-item a {
    font-size: 18px;
    color: #888888;
    border-radius: 5px;
    padding: 12px 20px 12px 16px;
    transition: 0.3s all;
    border: 1px solid transparent;
    margin-bottom: 10px;
}

.settings-menu .menu-item:last-child a {
    margin-bottom: 0;
}

.settings-menu .menu-item a img {
    margin-right: 18px;
}

.settings-menu .menu-item.active a,
.settings-menu .menu-item a:hover {
    background-color: #ECF9FF;
    border: 1px solid #89CFF0;
}

.settings-menu .menu-item a svg path {
    transition: 0.3s all;
}

.settings-menu .menu-item.active a svg path,
.settings-menu .menu-item a:hover svg path {
    fill: #009EFF;
}


/* ===============Account detail css include here=================== */

.settings-heading {
    border-bottom: 1px solid #DBDBDB;
    margin-bottom: 25px;
    padding-bottom: 18px;
}


/* =========================About Boome page css include here================== */

.about-boome .video-sec .playbtn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


/* ==============Social accounts page css include here=========== */

.social-accounts .inner-sec {
    background-color: #F1F1F1;
    border-radius: 6px;
    border: 1px solid #CECECE;
    padding: 25px 20px 15px 20px;
}

.social-accounts .inner-sec .icon-outer {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 5px 5px 6px rgba(0 0 0 / 16%) inset;
    margin-bottom: 20px;
}

.social-accounts .inner-sec h3 {
    color: #888888;
    margin-bottom: 3px;
}

.social-accounts .inner-sec p {
    margin-bottom: 5px;
}

.social-accounts .inner-sec .btn {
    padding: 10px 5px;
    line-height: 0;
    box-shadow: none;
}

.social-accounts .inner-sec .dropdown-menu {
    transform: translate(0, 28px) !important;
    padding: 0px;
    min-width: 105px;
    border: none;
    box-shadow: 0 3px 6px rgba(0 0 0 / 11%);
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    width: fit-content;
}

.social-accounts .inner-sec .dropdown-menu .dropdown-item {
    font-size: 14px;
    background: transparent;
    color: #888888;
    text-decoration: none;
    text-align: center;
    padding: 6px 0;
}


/* ==social accounts connected== */

.social-accounts .inner-sec.facebook.connected {
    border-color: #324B80;
}

.social-accounts .inner-sec.youtube.connected {
    border-color: #FE0000;
}

.social-accounts .inner-sec.instagram.connected {
    border-color: #E30082;
}

.social-accounts .inner-sec.tiktok.connected {
    border-color: #000000;
}

.social-accounts .inner-sec.facebook.connected h3 {
    color: #324B80;
}

.social-accounts .inner-sec.youtube.connected h3 {
    color: #FE0000;
}

.social-accounts .inner-sec.instagram.connected h3 {
    color: #E30082;
}

.social-accounts .inner-sec.tiktok.connected h3 {
    color: #000000;
}


/* ==========social accounts disconnected=========== */

.social-accounts .inner-sec.disconnected p img {
    opacity: 0.5;
}

.social-accounts .inner-sec.disconnected svg path {
    fill: #CECECE;
}


/* ===========Privacy policy page========== */

.privacy-sec ul li {
    padding-left: 23px;
}

.privacy-sec ul li::after {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    background-color: #89CFF0;
    left: 0;
    top: 9px;
    content: '';
}


/* ==============chat css include here============== */

.chat-users .user-item {
    padding-top: 11px;
    padding-bottom: 11px
}

.chat-users .user-item .img-wrap {
    height: 47px;
    width: 47px;
    border-radius: 50px;
}

.chat-users .user-item .content-sec {
    flex: 1;
}

.chat-users .user-item.active {
    background-color: #F1F1F1;
}

.chat-users .user-item p {
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.chat-users .user-item .msg-count {
    min-width: 25px;
    min-height: 25px;
    background-color: #009EFF;
    color: #fff;
    border-radius: 13px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 5px;
    margin-left: 10px;
}

.chat-users {
    height: calc(100vh - 338px);
    overflow: hidden;
    overflow-y: auto;
}

.chat-sec {
    overflow: hidden;
}

.chat-sec .top-sec {
    background-color: #DCDCDC;
    padding: 15px 20px;
}

.chat-sec .top-sec .img-wrap {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
}

.chat-sec .top-sec p::before {
    content: '';
    height: 11px;
    width: 11px;
    background-color: #02b315;
    display: inline-block;
    border-radius: 50%;
    margin-right: 7px;
}

.chat-sec .top-sec p.offline_status::before {
    
    background-color: #898989;
   
}

.chat-sec .top-sec p.typing_status::before {
    display: none !important;
   
}

.chat-sec .top-sec p.typing_status {color: #6e6e6e !important;}

.chat-sec .chat-messages {
    padding: 15px 20px;
}

.chat-sec .chat-messages .msg-content {
    width: fit-content;
    margin-bottom: 16px;
}

.chat-sec .chat-messages .user-msg {
    width: 65%;
}

.chat-sec .chat-messages .user-msg h5 {
    padding: 15px 24px;
    background-color: #F1F1F1;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    margin-bottom: 10px;
}

.chat-sec .chat-messages .self-msg {
    width: 65%;
}

.chat-sec .chat-messages .self-msg h5 {
    padding: 15px 24px;
    background-color: #009EFF;
    border-radius: 15px;
    border-bottom-right-radius: 0;
    margin-bottom: 10px;
}

.chat-sec .chat-messages {
    height: calc(100vh - 400px);
    overflow: hidden;
    overflow-y: auto;
}

.chat-sec .bottom-sec {
    box-shadow: 0 -3px 21px rgba(0 0 0 / 12%);
    padding: 10px 22px;
}

.chat-sec .bottom-sec .btn {
    padding: 0;
    margin-right: 20px;
    box-shadow: none;
}

.chat-sec .bottom-sec .form-control {
    border-radius: 0;
    border: none;
    border-left: 1px solid #DBDBDB;
    padding-left: 18px;
    box-shadow: none !important;
}


/* =================================================================================
==========================TALENT INTERFACE CSS INCLUDE HERE=========================
================================================================================= */

/* .category-check .btn {
    border: 1px solid #DCDCDC;
    border-radius: 6px;
    background-color: #fff;
    font-size: 14px;
    color: #232322;
    font-weight: 600;
    box-shadow: none !important;
    padding: 6px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
} */

.category-check .btn {
    border: 1px solid #DCDCDC;
    border-radius: 6px;
    background-color: #fff;
    font-size: 14px;
    color: #232322;
    font-weight: 600;
    box-shadow: none !important;
    padding: 6px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.category-check .btn img{
    max-width: 17px;
    margin-right: 6px;
}

.btn-check:checked+label {
    border-color: #89CFF0;
    background-color: #EFF6F9;
}

.upload-img-wrap {
    background-color: #F1F1F1;
    border: 1px dashed #707070;
    aspect-ratio: 1.6/1;
}

.upload-img-wrap input {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    opacity: 0 !important;
    cursor: pointer !important;
}

.talent-account .vcScroll {
    padding-bottom: 17px !important;
}


/* ===========Choose plan page css============= */

.subs-item {
    background-image: url('./assets/images/subs-free-bg.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    padding: 15px;
    border-radius: 20px;
    position: relative;
    margin-bottom: 30px;
    min-height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 8px 15px rgba(0 158 255 / 20%);
}

.subs-item.month {
    background-image: url('./assets/images/subs-halfyear-bg.png');
    box-shadow: 0 8px 15px rgba(78 90 255 / 20%);
}

.subs-item.year {
    background-image: url('./assets/images/subs-year-bg.png');
    box-shadow: 0 8px 15px rgba(254 98 144 / 20%);
}

.subs-item .check-img {
    width: 26px;
    top: -10px;
    right: 30px;
    z-index: 1;
    display: none;
    filter: drop-shadow( 0 -3px 6px rgba(104 104 104 / 16%));
}

.subs-item p {
    line-height: 1.2;
}

.subs-item input[type="radio"] {
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    top: 0;
    left: 0;
}

.subs-item input[type="radio"]:checked+ label .check-img {
    display: block;
}
.subs-item input[type="radio"]:checked+.check-img {
    display: block;
}


/* ==========Upgrade Subscription box css include here=========== */

.profile-subs {
    background-image: url(./assets/images/upgrade-plan.png);
    border-radius: 20px;
    padding: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
}

.profile-subs .img-wrap {
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background-color: rgba(255 255 255 / 10%);
    margin-right: 15px;
}


/* ================Task Item css include here================= */

.task-itm {
    border-radius: 5px;
    margin-top: 20px;
    padding: 22px;
    box-shadow: 0 3px 8px rgba(0 0 0 / 6%);
}

.task-itm .content-sec {
    flex: 1;
}

.task-itm .content-sec h3 img {
    margin-top: -3px;
    margin-right: 5px;
}

.task-itm .img-wrap {
    height: 70px;
    width: 70px;
    border-radius: 15px;
    overflow: hidden;
    margin-right: 20px;
}

.btn-reject {
    border: 1px solid #DCDCDC;
    background-color: #f0f0f0;
    color: #888888;
    box-shadow: 0 5px 10px rgb(0 0 0 / 9%);
}


/* =============User detail box detail============= */

.user-details .img-wrap {
    width: 95px;
    height: 95px;
    border-radius: 20px;
    overflow: hidden;
    margin: auto;
}

.user-details .content-box {
    padding-bottom: 25px;
}

.user-details .img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.user-details .star-sec img {
    width: 18px;
    margin-right: 5px;
}

.user-details .star-sec img:last-child {
    margin-right: 0;
}

.user-details .top-sec {
    border-bottom: 1px solid #DBDBDB;
}

.user-details h2 {
    margin-top: 23px;
    margin-bottom: 0;
}

.user-details h2 img {
    margin-right: 10px;
    width: 15px;
}

.user-details .bottom-btn {
    line-height: 1;
    background-color: #E0F3FF;
    padding: 17px;
    border-radius: 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    box-shadow: none;
}

.user-details .bottom-btn.rating-btn {
    background-color: #FFFDF2;
    color: #FFB15E;
}

.user-details.client-talent .btns-sec .btn {
    width: 135px;
    font-size: 16px;
}


/* =============Rating box item css include here============ */

.rating-itmbox {
    padding: 15px 13px;
    border-radius: 6px;
    border: 1px solid #CECECE;
    box-shadow: 0 4px 10px rgba(0 0 0 / 5%);
    margin-bottom: 15px;
}

.rating-itmbox:last-child {
    margin-bottom: 0;
}

.rating-itmbox .img-wrap {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid #EFF6F9;
    margin-right: 15px;
    overflow: hidden;
}


/* ==========Custom css include here=========== */

.nav-tabs.custom-tabs {
    justify-content: space-evenly;
}

.nav-tabs.custom-tabs .nav-link {
    background: transparent;
    border: none;
    font-size: 18px;
    color: #CECECE;
    font-weight: 400;
    position: relative;
    padding: 0 26px 13px 26px;
}

.nav-tabs.custom-tabs .nav-item.show .nav-link,
.nav-tabs.custom-tabs .nav-link.active {
    color: #FE6290;
    background: transparent;
}

.nav-tabs.custom-tabs .nav-item.show .nav-link::after,
.nav-tabs.custom-tabs .nav-link.active::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 3px;
    border-radius: 3px;
    bottom: -1px;
    background-color: #FE6290;
}


/* =========Active plan box css============= */

.active-plan {
    background-color: #EFF6F9;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 8px 15px rgba(0 0 0 / 10%);
}


/* ================Saved Card Css================== */

.saved-card {
    border: 1px solid #CECECE;
    border-radius: 4px;
    padding: 15px 20px;
}

.saved-card p {
    margin: 5px 0 5px 0;
}

.saved-card h3 {
    letter-spacing: 2px;
}

.add-card-box {
    border: 1px solid #CECECE;
    border-radius: 4px;
    padding: 15px 20px;
}


/* ===========transaction item css============ */

.transaction-itm {
    box-shadow: 0 4px 10px rgba(0 0 0 / 5%);
    padding: 13px 15px;
    border-radius: 6px;
    margin-top: 20px;
    cursor: pointer;
}

.transaction-itm .img-wrap {
    height: 65px;
    width: 65px;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid #EFF6F9;
}


/* ============Transaction detail css=========== */

.transaction-modal .img-wrap {
    height: 115px;
    width: 115px;
    border-radius: 10px;
    overflow: hidden;
}


/* ====================Verification page css include here================= */

.verification-crd .count-sec {
    height: 46px;
    width: 46px;
    border-radius: 50%;
    background-color: #FE6290;
}

.verification-crd .continuebtn {
    padding: 6px 30px;
}

.verification-crd .uploaded-img-wrap {
    height: 240px;
    width: 240px;
    border-radius: 50%;
    overflow: hidden;
    padding: 10px;
    border: 3px solid #89CFF0;
}

.verification-crd .uploaded-img-wrap img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.center-btns {
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-btns .btn {
    width: 150px;
    height: 48px;
    padding: 0;
}


/* ===============add-shots Css includes here=========== */

.add-shots .inner-sec .img-wrap {
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: #EFF6F9;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.add-shots .uploadbtn {
    font-size: 15px;
    padding: 4px 20px;
    margin-top: 12px;
    margin-bottom: 10px;
    width: fit-content;
    position: relative;
    display: block;
}

.add-shots .uploadbtn input {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}


/* ===========add slots uploaded========= */

.add-shots .inner-sec.uploaded h3 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    align-items: end;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    background-image: linear-gradient(rgba(255 255 255 / 0%), rgba(0 0 0 / 60%));
}

.add-shots .inner-sec.uploaded .img-wrap img {
    width: 100%;
    height: 100%;
}


/* =======mobile number field css===== */

.mobno-label .btns .btn {
    padding: 1px 12px;
    font-size: 14px;
    border-radius: 3px;
}

.mobno-label .btns .btn.verified {
    pointer-events: none;
}


/* ==============================================
========Client Interface css include here========
============================================== */

.requests-itm {
    box-shadow: 0 3px 8px rgba(0 0 0 / 8%);
    border-radius: 5px;
    margin-bottom: 25px;
    padding: 20px;
}

.requests-itm .content-sec h3 {
    cursor: pointer;
}

.requests-itm .img-wrap {
    height: 95px;
    width: 95px;
    border-radius: 15px;
    overflow: hidden;
}

.requests-itm .btn-sec .btn {
    padding: 6px 25px;
}


/* =========combined input and select======== */

.input-select input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-select select {
    width: fit-content;
    background-color: #DCDCDC;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #DCDCDC;
}


/* ============Talent user card css include hre============ */

.talent-itm {
    border-radius: 6px;
    border: 1px solid #CECECE;
    box-shadow: 0 4px 10px rgba(0 0 0 / 5%);
    margin-top: 20px;
    padding: 15px;
    cursor: pointer;
}

.talent-itm .img-wrap {
    height: 90px;
    width: 90px;
    border-radius: 10px;
    margin-right: 15px;
    overflow: hidden;
}

.talent-itm .right-rating img {
    width: 17px;
    margin-right: 6px;
}

.talent-itm .reviewcount {
    padding-left: 10px;
    padding-top: 4px;
}

.badge-row p {
    border-radius: 6px;
    padding: 3px 10px 2px 10px;
    margin-right: 10px;
    font-size: 15px;
}

.badge-row p:last-child {
    margin-right: 0;
}

.badge-row p.light-gray {
    background-color: #EAF6FE;
}

.badge-row p.light-pink {
    background-color: #FEF2F5;
}

.talent-itm h3 {
    margin-right: 22px;
}

.info-row h4 {
    border-right: 1px solid #ccc;
    padding: 0 15px;
}

.info-row h4:first-child {
    padding-left: 0;
}

.info-row h4:last-child {
    padding-right: 0;
    border-right: none;
}


/* =============mat date picker css============ */

.mat-form-field.custm-date .mat-form-field-wrapper {
    padding-bottom: 0;
}

.mat-form-field.custm-date .mat-form-field-infix {
    padding: 0;
    border: none;
}

.mat-form-field.custm-date .mat-form-field-underline {
    display: none;
}

.mat-form-field.custm-date .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 2em;
    fill: #FE6290;
}

.mat-button-focus-overlay {
    display: none;
}

.mat-ripple.mat-button-ripple.mat-button-ripple-round {
    display: none;
}


/* =============End============= */


/* =========Bill card css========= */

.bill-card {
    padding: 15px 25px;
    border-radius: 15px;
    background-color: #EFF6F9;
}

.bill-card p .right-txt {
    color: #232322;
}


/* ============Client talent booking page css ============ */

.talent-itm .booking-status::after {
    content: '';
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: #cccccc;
    position: absolute;
    left: -16px;
    top: 5px;
}

.talent-itm .booking-status.pending {
    color: #FE6290;
}

.talent-itm .booking-status.pending::after {
    background-color: #FE6290;
}

.detail-status {
    padding: 5px 15px 4px 33px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    position: relative;
}

.detail-status::after {
    content: '';
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #cccccc;
    position: absolute;
    left: 15px;
    top: 10px;
}

.detail-status.pending {
    color: #FE6290;
    background-color: #FFEEF3;
}

.detail-status.pending::after {
    background-color: #FE6290;
}

.detail-status.ongoing {
    color: #FFB15E;
    background-color: #FFEFDF;
}

.detail-status.ongoing::after {
    background-color: #FFB15E;
}

.detail-status.completed {
    color: #39CB33;
    background-color: #D7F5D6;
    padding: 5px 15px 4px 15px;
    display: flex;
    align-items: center;
}

.detail-status.completed img {
    margin-left: 8px;
}

.detail-status.completed::after {
    display: none;
}


/* ========texteditor css======= */

.angular-editor {
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    overflow: hidden;
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea {
    outline: none !important;
    border: none !important;
    min-height: 10rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-size: 1rem;
}

.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
    background-color: #fff;
    vertical-align: middle;
    border: 1px solid #DCDCDC;
    float: left;
    height: 30px;
    width: 30px;
    min-width: auto;
    padding: 0;
    margin-right: 5px;
    border-radius: 6px;
    margin-top: 4px;
    transition: 0.4s all;
}

.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button:hover {
    background-color: #65c4ff !important;
    border-color: #65c4ff !important;
    color: #ffffff;
}

.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button.active {
    background-color: #009EFF !important;
    border-color: #0096f3 !important;
    color: #ffffff;
}

.angular-editor-toolbar {
    border: none !important;
    padding: 10px !important;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav {
        align-items: center;
    }
}

.iti {
    width: 100%;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    cursor: no-drop;
    pointer-events: unset;
}

.date-input-outer mat-datepicker-toggle {
    position: absolute;
    right: 3px;
    top: 2px;
 }

 .img_cover{
    width: 100%;
    height: 100%;
    object-fit: cover;
 }


 /* ========area chart css========= */
 .engagement-sec .apexcharts-grid{
    display: none;
}
/* .engagement-sec .apexcharts-yaxis-texts-g{
    display: none;
}
.engagement-sec .apexcharts-xaxis-texts-g{
    display: none;
} */
.engagement-sec .apexcharts-toolbar{
    display: none;
}

.applied{
    background: transparent;
    color: #009EFF;
    border: 1px solid #009EFF;
    padding: 6px 45px;
    border-radius: 6px;
    font-size: 18px;
    display: block;
    width: fit-content;
}


.cdk-overlay-container {
    z-index: 10000;
}

.client-completed-rating span {
    display: block;
    width: 44px;
  }




  /* chat page css  */
.emoji-mart {position: absolute ; top: 42px;}



  input[type='file']{
    opacity: 0;
    cursor: pointer;
    width: 24px;
    height: 24px;
    font-size: 0;
    position: absolute;
}
input.camera_upload {opacity: 0;position: absolute;left: 73px;width: 50px; cursor: pointer !important;}
.chat_image {width: 220px; height: 220px; object-fit: cover; border-radius: 12px;padding-bottom: 5px;}

  /* End of chat page css  */

.linked{
    color: #fff;
    background-color: #009EFF;
    box-shadow: 0 5px 10px rgb(0 0 0 / 16%);
    border-radius: 6px;
    font-size: 18px;
    padding: 6px 50px;
    font-weight: 400;
    line-height: 1.5;
    display: inline-block;
}

.date-input-wrap{
    position: relative;
}
.date-input-wrap mat-datepicker-toggle{
    position: absolute;
    right: 0;
    top: 0;
}
.cursor_p{
    cursor: pointer !important;
}


